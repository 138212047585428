import React, { Memo, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../../utils/Variables";

const CartProduct = (props) => {
  const { product } = props;

  return (
    <div className="media cart-product">
      <Link
        to={`/${product.slug}/p/${product.id}`}
        className="mr-3 img-container"
        onClick={props.onCancel}
        style={{ height: "90px" }}
      >
        <img
          src={Api.CloudImageUrl + "/" + product.image}
          style={{ width: "90px" }}
          className="lazy_products product-img"
          alt={product.itemname}
        />
      </Link>

      <div className="media-body">
        <Link
          to={`/${product.slug}/p/${product.id}`}
          className="d-block text-body product-name"
          title={product.itemname}
          onClick={props.onCancel}
        >
          {product.itemname}
        </Link>
        {product.offer_id && product.offer_id !== null && (
          <span className="badge badge-warning off-badge">
            {product.offer_name} OFF
          </span>
        )}
        <div className="product-price">
          <em className="text-muted">
            {product.quantity} x ₹
            {product.salesdiscount > 0
              ? product.discount_sales_rate
              : product.salesrate}
          </em>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
