import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-hot-toast";

import PsContext from "../../../context/PsContext";
import API from "../../../utils/API";
import { integerIndMobile } from "../../../utils";

import ForgotPassword from "./forgotPassword";

const LoginForm = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState(null);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [useOtp, setUseOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoading(true);

    API.post("v1/site/user/check", $("#frm_userLogin").serialize()).then(
      (res) => {
        if (res["data"].status === "1") {
          setType(res["data"].type);
        } else {
          toast.error(res["data"].message, {
            style: {
              marginTop: "5%",
              borderRadius: "1px",
              background: "#333",
              color: "#fff",
            },
          });
        }
        setLoading(false);
      }
    );
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (!mobile) {
      toast.error("Enter mobile number first");
      return;
    }
    setOtpLoading(true);
    var fdata = new FormData();
    fdata.append("mobile", mobile);

    API.post("v1/site/user/customersendotp", fdata).then((res) => {
      if (res["data"].status === "1") {
        toast.success("OTP sent successfully");
        setOtpSent(true);
        setTimer(60);
      } else {
        toast.error(res["data"].message || "Failed to send OTP");
      }
      setOtpLoading(false);
    });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoading(true);

    API.post("v1/site/user/checkotp", $("#frm_new_User").serialize()).then(
      (res) => {
        if (res["data"].status === "1") {
          toast.success("Login Successfully...");
          context.userLogin(res["data"].userdata);
          if (props.afterFinish) props.afterFinish();
        } else {
          toast.error(res["data"].message, {
            style: {
              marginTop: "5%",
              borderRadius: "1px",
              background: "#333",
              color: "#fff",
            },
          });
        }
        setLoading(false);
      }
    );
  };

  const resetForm = () => {
    setMobile("");
    setPassword("");
    setOtp("");
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoading(true);

    const formData = $("#frm_usr_ext_login").serialize();
    const apiUrl = useOtp
      ? "v1/site/user/customerverifyotp"
      : "v1/site/userlogin";

    API.post(apiUrl, formData).then((res) => {
      if (res["data"].status === "1") {
        toast.success("Login Successfully...");
        context.userLogin(res["data"].userdata);
        if (props.afterFinish) props.afterFinish();
      } else {
        toast.error(res["data"].message, {
          style: {
            marginTop: "5%",
            borderRadius: "1px",
            background: "#333",
            color: "#fff",
          },
        });
      }
      setLoading(false);
    });
  };

  const termsLink = () => (
    <div style={{ fontSize: "12px" }}>
      By continuing, you agree to our{" "}
      <Link to="/privacy-policy" target="_blank">
        Terms of Use and our Privacy Policy
      </Link>
      .
    </div>
  );

  const forgotPasswordLink = () => (
    <Col md={12} className="mt-3">
      <a
        href="javascript:;"
        style={{ fontSize: "14px" }}
        onClick={() => setType("forgot")}
      >
        Forgot Password
      </a>
    </Col>
  );

  const mobileInput = () => (
    <Form
      action=""
      method="post"
      id="frm_userLogin"
      className="frm_login"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col md={12}>
          <label>Mobile </label>
          <InputGroup>
            <InputGroup.Text>+91</InputGroup.Text>
            <Form.Control
              type="number"
              name="mobile"
              placeholder="Mobile Number"
              required
              value={mobile}
              onKeyPress={integerIndMobile}
              onChange={(e) => setMobile(e.target.value)}
              autoFocus={true}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>{termsLink()}</Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Button type="submit" block disabled={loading}>
            {loading ? "Validating.." : "Continue"}
          </Button>
        </Col>
        {forgotPasswordLink()}
      </Row>
    </Form>
  );

  const signUpForm = () => (
    <Form
      action=""
      method="post"
      id="frm_new_User"
      className="frm_login"
      noValidate
      validated={validated}
      onSubmit={handleSignUp}
    >
      <Row>
        <Col md={12}>
          <label>Mobile </label>
          <InputGroup>
            <InputGroup.Text>+91</InputGroup.Text>
            <Form.Control
              type="number"
              name="mobile"
              placeholder="Mobile Number"
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <label>OTP </label>
          <Form.Control
            type="text"
            name="otp"
            placeholder="Enter OTP"
            required
            autoFocus={true}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <label>Password </label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Your Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>{termsLink()}</Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Button type="submit" block disabled={loading}>
            {loading ? "Validating.." : "Continue"}
          </Button>
        </Col>
        {forgotPasswordLink()}
      </Row>
    </Form>
  );

  const loginForm = () => (
    <Form
      action=""
      method="post"
      id="frm_usr_ext_login"
      className="frm_login"
      noValidate
      validated={validated}
      onSubmit={handleLoginSubmit}
    >
      <Row>
        <Col md={12}>
          <label>Mobile </label>
          <InputGroup>
            <InputGroup.Text>+91</InputGroup.Text>
            <Form.Control
              type="number"
              name="mobile"
              placeholder="Mobile Number"
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      {/* OTP-related section only visible when useOtp is true */}
      {useOtp && (
        <>
          <div
            style={{
              fontSize: "12px",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            {otpSent ? (
              timer > 0 ? (
                <p style={{ fontSize: "18px" }}>
                  Resend OTP in <span style={{ color: "red" }}>{timer}</span>{" "}
                  sec
                </p>
              ) : (
                <p style={{ fontSize: "18px" }}>
                  Didn’t get OTP?{" "}
                  <a href="javascript:;" onClick={handleSendOtp}>
                    Resend OTP
                  </a>
                </p>
              )
            ) : (
              <a
                style={{
                  backgroundColor: "#f1827b",
                  padding: "3px 18px",
                  color: "#fff",
                  borderRadius: "20px",
                  textAlign: "center",
                  marginTop: "14px",
                  display: "inline-block",
                  width: "100%",
                }}
                href="javascript:;"
                onClick={handleSendOtp}
              >
                Send OTP
              </a>
            )}
          </div>

          <Row className="mt-3">
            <Col md={12}>
              <label>OTP </label>
              <Form.Control
                type="text"
                name="otp"
                placeholder="Enter OTP"
                required
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Col>
          </Row>
        </>
      )}

      {!useOtp && (
        <Row className="mt-3">
          <Col md={12}>
            <label>Password </label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Your Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        <Col md={12}>
          <a
            href="javascript:;"
            style={{ fontSize: "14px" }}
            onClick={() => {
              setUseOtp(!useOtp);
              setOtp("");
              setPassword("");
            }}
          >
            {useOtp ? "Login with Password" : "Login with OTP instead"}
          </a>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>{termsLink()}</Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Button type="submit" block disabled={loading}>
            {loading ? "Validating.." : "Continue"}
          </Button>
        </Col>
        {forgotPasswordLink()}
      </Row>
    </Form>
  );

  return (
    <>
      {type === null && mobileInput()}
      {type === "new" && signUpForm()}
      {type === "customer" && loginForm()}
      {type === "forgot" && (
        <ForgotPassword mobile={mobile} afterFinish={props.afterFinish} />
      )}
    </>
  );
};

export default LoginForm;
